import React from 'react';

const GithubIcon = () => {
	return (
		<a
			href="https://github.com/FadlHassan"
			target="_blank"
			rel="noopener noreferrer"
		>
			<svg
				width="102"
				height="99"
				viewBox="0 0 102 99"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M51 0.399902C23.1666 0.399902 0.599976 22.6083 0.599976 49.9999C0.599976 73.2416 16.8624 92.689 38.7864 98.0706C38.5512 97.401 38.4 96.6239 38.4 95.6608V87.1834C36.3546 87.1834 32.9274 87.1834 32.0664 87.1834C28.6182 87.1834 25.5522 85.7243 24.0654 83.0128C22.4148 79.9996 22.1292 75.391 18.0384 72.572C16.8246 71.6338 17.7486 70.5632 19.1472 70.7079C21.7302 71.4271 23.8722 73.1714 25.8882 75.7588C27.8958 78.3504 28.8408 78.9374 32.5914 78.9374C34.41 78.9374 37.1316 78.834 39.6936 78.4372C41.0712 74.9942 43.4526 71.8239 46.3632 70.3276C29.58 68.6288 21.5706 60.4118 21.5706 49.2559C21.5706 44.453 23.6496 39.8071 27.1818 35.8928C26.0226 32.0075 24.5652 24.0839 27.627 21.0666C35.1786 21.0666 39.744 25.886 40.8402 27.188C44.6034 25.9191 48.7362 25.1999 53.079 25.1999C57.4302 25.1999 61.5798 25.9191 65.3514 27.1963C66.435 25.9026 71.0046 21.0666 78.573 21.0666C81.6474 24.088 80.1732 32.0447 79.0014 35.9218C82.5126 39.8278 84.579 44.4612 84.579 49.2559C84.579 60.4035 76.5822 68.6164 59.8242 70.3235C64.4358 72.6919 67.8 79.3466 67.8 84.3603V95.6608C67.8 96.0907 67.7034 96.4007 67.653 96.7686C87.2922 89.994 101.4 71.642 101.4 49.9999C101.4 22.6083 78.8334 0.399902 51 0.399902Z"
					fill="white"
				/>
			</svg>
		</a>
	);
};

export default GithubIcon;
