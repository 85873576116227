import React from 'react';

const InstagramIcon = () => {
	return (
		<a
			href="https://www.instagram.com/fadl8900/"
			target="_blank"
			rel="noopener noreferrer"
		>
			<svg
				width="80"
				height="78"
				viewBox="0 0 80 78"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M39.6462 0.25C3.45865 0.25 0.625 3.03867 0.625 38.6519V39.3481C0.625 74.9613 3.45865 77.75 39.6462 77.75H40.3538C76.5414 77.75 79.375 74.9613 79.375 39.3481V39C79.375 3.06325 76.5164 0.25 40 0.25H39.6462ZM32.125 8H47.875C69.7852 8 71.5 9.68756 71.5 31.25V46.75C71.5 68.3124 69.7852 70 47.875 70H32.125C10.2148 70 8.5 68.3124 8.5 46.75V31.25C8.5 9.68756 10.2148 8 32.125 8ZM59.676 15.75C57.5005 15.7558 55.7441 17.4954 55.75 19.6364C55.7559 21.7773 57.5236 23.5058 59.699 23.5C61.8745 23.4942 63.6309 21.7546 63.625 19.6136C63.6191 17.4727 61.8514 15.7442 59.676 15.75ZM39.9539 19.625C29.0824 19.6502 20.2869 28.3465 20.3125 39.0454C20.3381 49.7443 29.1747 58.4002 40.0461 58.375C50.9176 58.3498 59.7131 49.6535 59.6875 38.9546C59.6619 28.2557 50.8253 19.5998 39.9539 19.625ZM39.9731 27.375C46.4956 27.3595 51.7967 32.5546 51.8125 38.9735C51.8263 45.3924 46.5494 50.6095 40.0269 50.625C33.5044 50.6405 28.2033 45.4474 28.1875 39.0265C28.1717 32.6076 33.4506 27.3905 39.9731 27.375Z"
					fill="white"
				/>
			</svg>
		</a>
	);
};

export default InstagramIcon;
