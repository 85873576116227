import React from 'react';

const LinkedInIcon = () => {
	return (
		<a
			href="https://www.linkedin.com/in/fadl-praveesh-hassan/"
			target="_blank"
			rel="noopener noreferrer"
		>
			<svg
				width="102"
				height="100"
				viewBox="0 0 102 100"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M51.0001 0.399902C23.1667 0.399902 0.600098 22.6083 0.600098 49.9999C0.600098 77.3915 23.1667 99.5999 51.0001 99.5999C78.8335 99.5999 101.4 77.3915 101.4 49.9999C101.4 22.6083 78.8335 0.399902 51.0001 0.399902ZM32.0833 22.7323C35.6197 22.7323 37.9759 25.0511 37.9759 28.1428C37.9759 31.2346 35.6197 33.5534 31.6927 33.5534C28.1563 33.5575 25.8001 31.2346 25.8001 28.1428C25.8001 25.0511 28.1563 22.7323 32.0833 22.7323ZM38.4001 70.6666H25.8001V37.5999H38.4001V70.6666ZM80.4001 70.6666H68.5393V52.5956C68.5393 47.5984 65.3767 46.4452 64.1923 46.4452C63.0079 46.4452 59.0515 47.214 59.0515 52.5956C59.0515 53.3644 59.0515 70.6666 59.0515 70.6666H46.8001V37.5999H59.0557V42.2127C60.6349 39.5219 63.7975 37.5999 69.7279 37.5999C75.6583 37.5999 80.4001 42.2127 80.4001 52.5956V70.6666Z"
					fill="white"
				/>
			</svg>
		</a>
	);
};

export default LinkedInIcon;
